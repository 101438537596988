// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = (theme: any) => ({
  contentWrapper: {
    position: 'relative' as const,
    padding: '0px 24px 24px',
    '@media(max-width: 768px)': {
      padding: '0px 16px 16px',
    },
  },
  title: {
    color: theme.palette.common.blue400,
  },
  desc: {
    color: theme.palette.common.grey200,
  },
  listItem: {
    alignItems: 'center',
    padding: 0,
  },
  textListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '12px 0',
    fontSize: theme.typography.fontSize16,
    '@media(max-width: 600px)': {
      fontSize: theme.typography.fontSize14,
    },
  },
  primaryText: {
    color: theme.palette.common.grey500,
    lineHeight: 1.4,
  },
  secondaryText: {
    color: theme.palette.common.grey500,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default styles;
