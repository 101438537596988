import { FC } from 'react';
import NextImage, { ImageProps } from 'next/legacy/image';

// TODO: enhance for storybook use
const Image: FC<Record<any, any>> = (props) => {
  const { width, height, ...rest } = props;

  const formattedWidth =
    typeof width === 'string' ? Number(width?.replace('px', '')) : width;
  const formattedHeight =
    typeof height === 'string' ? Number(height?.replace('px', '')) : height;

  return (
    <NextImage
      {...(rest as ImageProps)}
      width={formattedWidth}
      height={formattedHeight}
      blurDataURL="data:image/jpeg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAADAAQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAf/xAAbEAADAAMBAQAAAAAAAAAAAAABAgMABAURUf/EABUBAQEAAAAAAAAAAAAAAAAAAAMF/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAECEf/aAAwDAQACEQMRAD8Anz9voy1dCI2mectSE5ioFCqia+KCwJ8HzGMZPqJb1oPEf//Z"
      placeholder="blur"
    />
  );
};

export default Image;
