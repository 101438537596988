const styles = (theme) => ({
  btn: {
    ...theme.typography.button,
    '&.MuiButton-outlined:hover': {
      border: 0,
    },
  },
});

export default styles;
