// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = (theme: any) => ({
  scrollPaper: {
    alignItems: 'flex-end',
  },
  mainWrapper: {
    padding: 0,
    '&:first-of-type': {
      paddingTop: 0,
    },
  },
  header: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.common.grey20}`,
  },
  headerContent: {
    padding: 20,
    display: 'flex',
    '@media(min-width: 768px)': {
      flexDirection: 'row-reverse' as const,
    },
  },
  closeIcon: {
    padding: 0,
    '@media(max-width: 768px)': {
      '& svg.MuiSvgIcon-root': {
        width: 20,
      },
    },
  },
  titleWrapper: {
    flex: '1 1 auto',
    textAlign: 'center' as const,
  },
  headerTitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
});

export default styles;
