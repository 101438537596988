import { useState } from 'react';

import { IconButton } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useConfigContext } from '@lumirental/lumi-web-shared/lib/contexts/Config.context';
import { getPublicCDNLink } from '@lumirental/lumi-web-shared/lib/utils/cdnLinks';

import { Image } from '@/components/common/Image';

import { DropoffChargesDialog } from '../Dialogs/DropoffCharges';

import styles from './DropoffChargesInfo.style';

const useStyles = makeStyles()(styles);

const DropoffChargesInfo: React.FC<{
  isSearchFunnel: boolean;
}> = ({ isSearchFunnel = false }) => {
  const [openDropoffChargesDialog, setOpenDropoffChargesDialog] =
    useState(false);

  const { classes } = useStyles();

  const { appEnv } = useConfigContext();

  const infoIcon = isSearchFunnel
    ? 'information-white-icon'
    : 'information-blue-icon';

  const handleOpenDropoffChargesDialog = () => {
    setOpenDropoffChargesDialog(true);
  };
  const handleCloseDropoffChargesDialog = () => {
    setOpenDropoffChargesDialog(false);
  };

  return (
    <>
      <IconButton
        aria-label="info"
        data-testid="dropoffChargesInfoIcon"
        sx={{ padding: 0, margin: 0, marginLeft: 0.5 }}
        className={classes.infoIcon}
      >
        <Image
          alt="info"
          src={getPublicCDNLink(`/images/svg/${infoIcon}.svg`, appEnv)}
          width={20}
          height={20}
          onClick={handleOpenDropoffChargesDialog}
        />
      </IconButton>
      <DropoffChargesDialog
        isOpen={openDropoffChargesDialog}
        onClose={handleCloseDropoffChargesDialog}
      />
    </>
  );
};

export default DropoffChargesInfo;
