import { useTranslation } from 'react-i18next';

import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { DEFAULT_CURRENCY } from '@lumirental/lumi-web-shared/lib/constants/date/DEFAULT_CURRENCY';
import { useConfigContext } from '@lumirental/lumi-web-shared/lib/contexts/Config.context';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import typography from '@lumirental/lumi-web-theme/lib/lumi.typography';

import { Button } from '@/components/common/Button';
import { ResponsiveDialog } from '@/components/common/Dialogs/Responsive';

import styles from './DropoffChargesDialog.style';

const useStyles = makeStyles()(styles);

const DROP_OFF_CHARGES = {
  INTRA_CITY: 0,
  INTRA_REGION: 50,
  INTER_REGION: 300,
};

interface DropoffChargesDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

const DropoffChargesDialog: React.FC<DropoffChargesDialogProps> = ({
  isOpen,
  onClose,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { isMobile } = useConfigContext();

  const dropoffChargesList = [
    {
      id: 1,
      title: t('rental_policy_different_branch_point_1'),
      value: t('Free'),
    },
    {
      id: 2,
      title: t('rental_policy_different_branch_point_3'),
      value: `${DROP_OFF_CHARGES.INTRA_REGION} ${DEFAULT_CURRENCY}`,
    },
    {
      id: 3,
      title: t('rental_policy_different_branch_point_4'),
      value: `${DROP_OFF_CHARGES.INTER_REGION} ${DEFAULT_CURRENCY}`,
    },
  ];

  return (
    <ResponsiveDialog
      isMobile={isMobile}
      open={isOpen}
      onClose={onClose}
      dataTestid="dropoffChargesInfoDialog"
    >
      <Box className={classes.contentWrapper}>
        <Box sx={{ marginBottom: 3 }}>
          <Typography
            sx={{ marginBottom: 1 }}
            variant="h2"
            className={classes.title}
          >
            {t('modal_title_rental_poilcy_different_branch_drop_off')}
          </Typography>
          <Typography variant="body" className={classes.desc}>
            {t('rental_policy_text_different_branch_drop_off')}
          </Typography>
        </Box>
        <List sx={{ padding: 0, marginBottom: 2 }}>
          {dropoffChargesList.map((dropoffItem, index) => {
            const isLastItem = index === dropoffChargesList.length - 1;
            return (
              <Box key={dropoffItem?.id}>
                <ListItem className={classes.listItem}>
                  <ListItemText
                    primary={dropoffItem?.title}
                    secondary={dropoffItem?.value}
                    classes={{
                      root: classes.textListItem,
                      primary: classes.primaryText,
                      secondary: classes.secondaryText,
                    }}
                  />
                </ListItem>
                {!isLastItem && <Divider />}
              </Box>
            );
          })}
        </List>
        {!isMobile && (
          <Button
            fullWidth
            dataTestid="acknowledgeDropoffCharges"
            onClick={onClose}
          >
            {t('modal_btn_acknowledged')}
          </Button>
        )}
      </Box>
    </ResponsiveDialog>
  );
};

export default DropoffChargesDialog;
