import { Close } from '@mui/icons-material';
import {
  Dialog as MUIDialog,
  DialogContent,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import type { DialogBaseProps } from './DialogBase.types';

import styles from './DialogBase.style';

const useStyles = makeStyles()(styles);

const DialogBase = ({
  open,
  title,
  fullScreen,
  hideAppBar,
  dataTestid = 'Lumi-dialog',
  className,
  children,
  onClose,
}: DialogBaseProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <MUIDialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      classes={{
        scrollPaper: fullScreen ? classes.scrollPaper : null,
        paper: className,
      }}
      data-testid={dataTestid}
    >
      <DialogContent className={classes.mainWrapper}>
        {!hideAppBar && (
          <Toolbar className={classes.headerContent}>
            <IconButton
              aria-label="close"
              edge="start"
              size="small"
              data-testid="dialogCloseBtn"
              className={classes.closeIcon}
              onClick={onClose}
            >
              <Close />
            </IconButton>
            {title && (
              <div className={classes.titleWrapper}>
                <Typography variant="h6" className={classes.headerTitle}>
                  {title}
                </Typography>
              </div>
            )}
          </Toolbar>
        )}

        {children}
      </DialogContent>
    </MUIDialog>
  );
};

export default DialogBase;
