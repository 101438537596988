// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = (theme: any) => ({
  contentWrapper: {
    position: 'relative' as const,
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'flex-end',
  },
  title: {
    color: theme.palette.common.blue400,
  },
  desc: {
    color: theme.palette.common.grey200,
  },
  btnsWrapper: {
    gap: 16,
  },
});

export default styles;
