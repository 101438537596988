const styles = () => ({
  infoIcon: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default styles;
