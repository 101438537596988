import CloseIcon from '@mui/icons-material/Close';
import type { DialogClasses, DialogProps } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';

import { makeStyles } from 'tss-react/mui';

import { Button } from '@/components/common/Button';
import SwipeableDrawerBase from '@/components/common/Dialogs/SwipeableDrawer';

import styles from './MobileDialog.style';

const useStyles = makeStyles()(styles);

interface DialogBaseProps extends DialogProps {
  dataTestid: string;
  onClose: () => void;
  dialogClass: Partial<DialogClasses>;
}

const MobileDialog = ({
  children,
  open,
  onClose,
  dataTestid,
  dialogClass,
}: DialogBaseProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <SwipeableDrawerBase
      isOpen={open}
      onClose={onClose}
      dataTestid={dataTestid}
      classes={dialogClass}
    >
      <AppBar position="static" className={classes.header}>
        <Toolbar className={classes.headercontent}>
          <Button
            className={classes.expandBtn}
            dataTestid="dialogExpandBtn"
            onClick={onClose}
          />
          <IconButton
            edge="start"
            size="small"
            className={classes.closeIcon}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {children}
    </SwipeableDrawerBase>
  );
};

export default MobileDialog;
