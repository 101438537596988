import type { DialogProps } from '@mui/material';

import DialogBase from '@/components/common/Dialogs/DialogBase/DialogBase';

interface DialogBaseProps extends DialogProps {
  dataTestid: string;
  onClose: () => void;
  hideAppBar?: boolean;
  className?: string;
}

const DesktopDialog = ({
  children,
  open,
  onClose,
  dataTestid,
  hideAppBar,
  className,
}: DialogBaseProps): JSX.Element => {
  return (
    <DialogBase
      open={open}
      onClose={onClose}
      dataTestid={dataTestid}
      hideAppBar={hideAppBar}
      className={className}
    >
      {children}
    </DialogBase>
  );
};

export default DesktopDialog;
