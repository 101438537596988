// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = (theme: any) => ({
  banner: {
    width: '100%',
    position: 'relative' as const,
    textAlign: 'center' as const,
  },
  expandBtn: {
    width: 40,
    padding: 0,
    minWidth: 'auto',
    height: 5,
    backgroundColor: theme.palette.common.grey50,
    borderRadius: 100,
    margin: '0 auto',
    cursor: 'pointer',
    zIndex: theme.zIndex.zIndex10,
    '&:hover, &:active': {
      backgroundColor: theme.palette.common.grey50,
    },
  },
});

export default styles;
