import React, { useMemo } from 'react';

import { Button as MuiButton, CircularProgress } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { ButtonProps } from './Button.types';

import styles from './Button.style';

const useStyles = makeStyles()(styles);

export const Button: React.FC<ButtonProps> = ({
  variation = 'primary',
  size = 'medium',
  className = '',
  isLoading,
  disabled,
  dataTestid = 'Lumi-button',
  children,
  onClick,
  ...rest
}): JSX.Element => {
  const { classes } = useStyles();

  const allClasses = `${classes.btn} ${className}`;

  const muiVariation = useMemo(() => {
    switch (variation) {
      case 'ghost':
        return 'outlined';
      case 'text':
        return 'text';
      default:
        return 'contained';
    }
  }, [variation]);

  return (
    <MuiButton
      variant={muiVariation}
      className={allClasses}
      disabled={isLoading || disabled}
      size={size}
      data-testid={dataTestid}
      onClick={onClick}
      {...rest}
    >
      {children}
      {isLoading && (
        <CircularProgress
          size={30}
          color="secondary"
          style={{ position: 'absolute', right: 20 }}
        />
      )}
    </MuiButton>
  );
};

export default Button;
