// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const styles = () => ({
  dialog: {
    width: '100%',
    maxWidth: 440,
    borderRadius: 8,
  },
  paperFullScreen: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
});

export default styles;
