const styles = (theme: any) => ({
  header: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    margin: 0,
  },
  headercontent: {
    padding: '12px 16px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  expandBtn: {
    width: 40,
    padding: 0,
    minWidth: 'auto',
    height: 5,
    backgroundColor: theme.palette.common.grey50,
    borderRadius: 100,
    margin: '0 auto',
    cursor: 'pointer',
    zIndex: theme.zIndex.zIndex10,
    '&:hover, &:active': {
      backgroundColor: theme.palette.common.grey50,
    },
  },
  closeIcon: {
    position: 'absolute' as const,
  },
});

export default styles;
