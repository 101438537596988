import { AppBar, DialogContent as MUIDialogContent } from '@mui/material';
import MUISwipeableDrawer from '@mui/material/SwipeableDrawer';

import { makeStyles } from 'tss-react/mui';

import { Button } from '@/components/common/Button';

import type { SwipeableDrawerBaseProps } from './SwipeableDrawerBase.types';

import styles from './SwipeableDrawerBase.style';

const useStyles = makeStyles()(styles);

function DialogContent({ children }: { children: React.ReactNode }) {
  return <MUIDialogContent sx={{ padding: 2 }}>{children}</MUIDialogContent>;
}

function DialogHeader({
  children,
  onClose,
  customStyle,
}: {
  children?: React.ReactNode;
  onClose?: SwipeableDrawerBaseProps['onClose'];
  customStyle?: { appBar?: any; banner?: any; expandBtn?: any };
}) {
  const { classes } = useStyles();

  return (
    <AppBar position="static" style={customStyle?.appBar}>
      <div className={classes.banner} style={customStyle?.banner}>
        <Button
          className={classes.expandBtn}
          style={customStyle?.expandBtn}
          dataTestid="dialogExpandBtn"
          onClick={onClose}
        />

        {children}
      </div>
    </AppBar>
  );
}

const SwipeableDrawerBase = ({
  isOpen,
  dataTestid = 'Lumi-swipeable-drawer',
  children,
  onClose,
  ...props
}: SwipeableDrawerBaseProps): JSX.Element => {
  return (
    <MUISwipeableDrawer
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
      onOpen={null}
      data-testid={dataTestid}
      disableDiscovery
      {...props}
    >
      {children}
    </MUISwipeableDrawer>
  );
};

SwipeableDrawerBase.Header = DialogHeader;
SwipeableDrawerBase.Content = DialogContent;

export default SwipeableDrawerBase;
