import { makeStyles } from 'tss-react/mui';

import { DesktopDialog } from '../DesktopDialog';
import { MobileDialog } from '../MobileDialog';

import styles from './ResponsiveDialog.style';

const useStyles = makeStyles()(styles);

interface ResponsiveDialogProps {
  children: React.ReactNode;
  isMobile: boolean;
  open: boolean;
  onClose: () => void;
  dataTestid: string;
}

const ResponsiveDialog: React.FC<ResponsiveDialogProps> = ({
  children,
  isMobile,
  open,
  onClose,
  dataTestid,
}) => {
  const { classes } = useStyles();

  return isMobile ? (
    <MobileDialog
      open={open}
      onClose={onClose}
      dataTestid={dataTestid}
      dialogClass={{
        paper: classes.paperFullScreen,
      }}
    >
      {children}
    </MobileDialog>
  ) : (
    <DesktopDialog
      open={open}
      onClose={onClose}
      dataTestid={dataTestid}
      className={classes.dialog}
    >
      {children}
    </DesktopDialog>
  );
};

export default ResponsiveDialog;
