import { useTranslation } from 'react-i18next';

import { Box, Divider, LinearProgress, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useConfigContext } from '@lumirental/lumi-web-shared/lib/contexts/Config.context';
import { getPublicCDNLink } from '@lumirental/lumi-web-shared/lib/utils/cdnLinks';

import { Button } from '@/components/common/Button';
import { ResponsiveDialog } from '@/components/common/Dialogs/Responsive';
import { Image } from '@/components/common/Image';

import styles from './DifferentCityRegionDialog.style';

const useStyles = makeStyles()(styles);

interface DifferentCityRegionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDropoffConfirm: () => void;
  isLoading: boolean;
  dropOffChargesInfo: {
    title: string;
    description: string;
    imgUrl: string;
  };
}

const DifferentCityRegionDialog: React.FC<DifferentCityRegionDialogProps> = ({
  isOpen,
  onClose,
  dropOffChargesInfo,
  isLoading,
  onDropoffConfirm,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { isMobile, appEnv } = useConfigContext();

  const flexDirection = isMobile ? 'column' : 'row-reverse';

  return (
    <ResponsiveDialog
      isMobile={isMobile}
      open={isOpen}
      onClose={onClose}
      dataTestid="savedCardDialog"
    >
      {isLoading && <LinearProgress />}
      <Box
        sx={{
          padding: isMobile ? 2 : 3,
          paddingTop: 0,
          textAlign: 'center',
          minHeight: 340,
        }}
        className={classes.contentWrapper}
      >
        {dropOffChargesInfo?.imgUrl && (
          <Box sx={{ marginBottom: isMobile ? 3 : 1 }}>
            <Image
              alt="Drop place"
              src={getPublicCDNLink(dropOffChargesInfo?.imgUrl, appEnv)}
              width={146}
              height={120}
            />
          </Box>
        )}

        <Box sx={{ marginBottom: 3 }}>
          {dropOffChargesInfo?.title && (
            <Typography
              sx={{ marginBottom: 1 }}
              variant="h2"
              className={classes.title}
            >
              {dropOffChargesInfo?.title}
            </Typography>
          )}
          {dropOffChargesInfo?.description && (
            <Typography variant="body" className={classes.desc}>
              {dropOffChargesInfo?.description}
            </Typography>
          )}
          {!isMobile && (
            <Divider
              sx={{
                marginTop: 3,
              }}
            />
          )}
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection }}
          className={classes.btnsWrapper}
        >
          <Box>
            <Button
              fullWidth={isMobile}
              dataTestid="noKeepSaveCardBtn"
              onClick={onDropoffConfirm}
              sx={{ minWidth: isMobile ? 220 : 190 }}
            >
              {t('modal_primary_button_label_different_drop_off_charges')}
            </Button>
          </Box>
          <Box>
            <Button
              variation="text"
              dataTestid="saveCardRemoveBtn"
              onClick={onClose}
              sx={{ minWidth: isMobile ? 220 : 190, height: '100%' }}
            >
              {t('modal_secondary_button_label_choose_different_location')}
            </Button>
          </Box>
        </Box>
      </Box>
    </ResponsiveDialog>
  );
};

export default DifferentCityRegionDialog;
